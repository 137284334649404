import React from 'react';

import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography
} from '@mui/material';


import { Download } from '@mui/icons-material';

// Sample data
const rows = [
    {
        type: 'PDF',
        document: 'SETC Program FAQ’s',
        size: '218 KB',
        url:'https://pub-c2fa5e3399014ea4bf87087ccafe5f14.r2.dev/SETC%20Program%20-%20FAQ\'s%20(1).pdf'
    },
    {
        type: 'PDF',
        document: 'SETC - Promo Emails',
        size: '98 KB',
        url:'https://pub-c2fa5e3399014ea4bf87087ccafe5f14.r2.dev/SETC%20Promo%20Emails.pdf'
    },
    {
        type: 'PDF',
        document: 'SETC - Webclass Promo Emails',
        size: '112 KB',
        url:'https://pub-c2fa5e3399014ea4bf87087ccafe5f14.r2.dev/SETC%20Webclass%20Promo%20Email%20Sequence.pdf'

    },
    {
        type: 'PDF',
        document: 'SETC - Flyer June 2024',
        size: '5.3 MB',
        url:'https://pub-c2fa5e3399014ea4bf87087ccafe5f14.r2.dev/SETC_FLYER_JUNE_2024.pdf'

    }
];

const DataGridComponent = () => {
    return (
        <Grid  container justifyContent="center" style={{ padding: '20px' }}>
            <Grid item xs={12} md={12}>
                <Paper style={{ padding: '20px' }}>
                    <Typography mb={2} align={'left'}
                                variant={'h3'}
                    >Getting Started Resources</Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Document</TableCell>
                                    <TableCell>Size</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.document}</TableCell>
                                        <TableCell>{row.size}</TableCell>
                                        <TableCell>
                                            <Button

                                                variant="outlined"
                                                startIcon={<Download />}
                                                size="small"
                                                component="a"
                                                href={row.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                DOWNLOAD
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <p>Total Rows: {rows.length}</p>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default DataGridComponent;

