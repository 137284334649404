import { useSetAtom } from 'jotai'
import {
  boxTokenAtom,
  boxUserFolderIdAtom,
  userIdAtom,
  userInfoReducerAtom,
  userTokenAtom,
  userTypeAtom,
} from '../atoms'
import { useRouter } from 'next/navigation'

const useLogout = () => {
  const router = useRouter()
  const setToken = useSetAtom(userTokenAtom)
  const setType = useSetAtom(userTypeAtom)
  const setUserId = useSetAtom(userIdAtom)
  const setUserInfo = useSetAtom(userInfoReducerAtom)
  const setBoxToken = useSetAtom(boxTokenAtom)
  const setBoxFolderId = useSetAtom(boxUserFolderIdAtom)

  const logout = () => {
    localStorage.removeItem('referral_code')
    setUserId('')
    setToken('')
    setType('unknown')
    setUserInfo({ shouldClear: true })
    setBoxToken('')
    setBoxFolderId('')
    router.replace('/')
  }
  return logout
}

export default useLogout
