import { Box, Button, Drawer, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import useResumeAuth from '../hooks/useResumeAuth'
import { FormTextInput } from '../steps/formComponents/FormTextInput'
import { useRouter } from 'next/navigation'
import { useAtomValue } from 'jotai'
import { userTokenAtom } from '../atoms'
import useUserInfo from '../hooks/useUserInfo'

interface ResumeButtonProps {
  foundEmail?: string
  buttonText?: string
  showStartNew?: boolean
}
const ResumeButton: React.FC<ResumeButtonProps> = ({ buttonText, showStartNew }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [resultsString, setResultsString] = useState('')
  const router = useRouter()

  const startNewApplication = () => {
    router.push('/')
  }

  return (
    <React.Fragment key={'bottom'}>
      <div
        className="flex flex-row text-purple-700 font-semibold p-2 justify-center items-center flex-1 text-sm bg-white rounded-lg cursor-pointer"
        onClick={() => setDrawerOpen(true)}>
        {buttonText ?? 'Login or Resume Application'}
      </div>
      <Drawer
        PaperProps={{
          className: 'flex flex1 flex-col justify-between items-center pt-16 px-4 py-16',
          sx: {
            width: '100vw',
            maxWidth: 675,
            minHeight: 400,
            elevation: 8,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          },
        }}
        variant="temporary"
        anchor={'bottom'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}>
        <ResumeSection isEnabled={drawerOpen} onResults={setResultsString} />
        {showStartNew ? (
          <div className="flex flex-1 flex-row justify-center gap-12">
            <div className="text-button items-center text-sm" onClick={() => setDrawerOpen(false)}>
              {!resultsString ? 'Cancel' : 'Close'}
            </div>
          </div>
        ) : (
          <div className="text-button justify-center items-center text-sm" onClick={() => setDrawerOpen(false)}>
            {!resultsString ? 'Cancel' : 'Close'}
          </div>
        )}
      </Drawer>
    </React.Fragment>
  )
}

export default ResumeButton

export function ResumeSection({
  isEnabled = true,
  onResults,
}: {
  isEnabled: boolean
  onResults?: (results: string) => void
}) {
  const { isFetching, requestMagicLink } = useResumeAuth()
  const [resultsString, setResultsString] = useState('')
  const userToken = useAtomValue(userTokenAtom)
  const [ ,,,{ logout }] = useUserInfo()
  const [loginWithADifferentEmail, setLoginWithADifferentEmail] = useState(false)

  const loginState =
    userToken && loginWithADifferentEmail
      ? 'relogin_with_email'
      : userToken
        ? 'relogin_with_token'
        : 'relogin_with_email'

  const {
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<{ email: string }>({ defaultValues: { email: '' } })

  const { email } = watch()

  useEffect(() => {
    if (!isEnabled && (!!resultsString || !!email)) {
      reset()
      setResultsString('')
      onResults?.('')
    }
  }, [isEnabled])

  const handleRequest = async ({ email }: { email?: string }) => {
    const requestSent = await requestMagicLink(email)
    if (requestSent) {
      if (email) {
        setResultsString(`A magic link was sent to "${email}"!\nIt should arrive shortly.`)
        onResults?.(`A magic link was sent to "${email}"!\nIt should arrive shortly.`)
      } else {
        setResultsString(`A magic link was sent to your email!\nIt should arrive shortly.`)
        onResults?.(`A magic link was sent to your email!\nIt should arrive shortly.`)
      }
    } else {
      setResultsString(
        `There was a problem checking for an existing application. Please start a new application, or try again later.`
      )
      onResults?.(
        `There was a problem checking for an existing application. Please start a new application, or try again later.`
      )
    }
  }

  return (
    <>
      {!!resultsString ? (
        <Typography textAlign={'center'}>{resultsString}</Typography>
      ) : (
        <Box className="flex flex1 flex-col items-stretch gap-4" sx={{ maxWidth: 450 }}>
          {loginState === 'relogin_with_email' ? (
            <>
              <Typography textAlign={'center'}>Enter your email to login</Typography>
              <FormTextInput
                name="email"
                type="email"
                autoComplete="email"
                rules={{
                  required: true,
                  pattern: { value: /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/, message: 'Please enter a valid email' },
                }}
                label="Email"
                {...formMethods}
              />
            </>
          ) : (
            <>
              <Typography textAlign={'center'}>Your login has expired.</Typography>
              <Typography textAlign={'center'}>You must re-login to continue</Typography>
              <Typography textAlign={'center'}>Press the button to get your link</Typography>
            </>
          )}
          <Button
            sx={{ maxWidth: 400, alignSelf: 'center' }}
            type="submit"
            onClick={handleSubmit(handleRequest)}
            variant="contained">
            Request Magic Link
          </Button>
          {loginState !== 'relogin_with_email' ? (
            <>
              <Button type="button" variant="text" onClick={() => {
                logout?.()
              }} style={{ color: '#4e4e4e' }}>
                Logout
              </Button>
            </>
          ) : null}
        </Box>
      )}
    </>
  )
}
