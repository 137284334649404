import { useEffect, useState } from 'react'
import { devlog } from '../utils'
import useUserInfo from './useUserInfo'
import LogRocket from 'logrocket'

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/tag-platform/gtagjs/reference/events#page_view

type CMC_GA_EVENTS =
  | 'prequalification_start'
  | 'prequalification_finish'
  | 'prequalification_ineligible'
  | 'sign_up'
  | 'sign_up_phone'
  | 'financial_info_completed'
  | 'dates_completed'
  | 'application_submitted'

export default function useGTagOnRender(pageName: string, extraEvent?: CMC_GA_EVENTS) {
  const [firstRender, setFirstRender] = useState(false)
  const [userInfo] = useUserInfo()

  useEffect(() => {
    setFirstRender(true)
  }, [])

  useEffect(() => {
    if (firstRender && GA_MEASUREMENT_ID) {
      setFirstRender(false)
      const w = window as any
      w.gtag?.('event', 'page_view', {
        page_title: pageName,
        page_location: 'https://portal.setccredit.org',
        user_id: userInfo?.id,
      })
      w.gtag?.('event', 'form_step', {
        step_name: pageName,
      })
      if (extraEvent) {
        trackEvent(extraEvent)
      }
      if (!w.gtag || typeof w.gtag !== 'function') {
        devlog('gtag not found, page_view', w.gtag)
      } else {
        devlog('gtag found, page_view', w.gtag)
      }
    }
  }, [firstRender])

  return null
}

export const trackEvent = (event: CMC_GA_EVENTS, user_id?: string) => {
  if (!GA_MEASUREMENT_ID) return
  const w = window as any
  w.gtag?.('event', event, {
    user_id,
  })
  LogRocket.track(event)
  if (!w.gtag || typeof w.gtag !== 'function') {
    devlog('gtag not found, signup', w.gtag)
  } else {
    devlog('gtag found, signup', w.gtag)
  }
}

export const trackUniqueEvent = (event: CMC_GA_EVENTS, forStep: number, currentStep: number, user_id: string) => {
  if (!GA_MEASUREMENT_ID) return
  const w = window as any
  if (currentStep <= forStep) {
    w.gtag?.('event', event, {
      user_id,
    })
    LogRocket.track(event)
  }
  if (!w.gtag || typeof w.gtag !== 'function') {
    devlog('gtag not found, signup', w.gtag)
  } else {
    devlog('gtag found, signup', w.gtag)
  }
}

export const trackLogin = (method: 'email' | 'phone' | 'google') => {
  if (!GA_MEASUREMENT_ID) return
  const w = window as any
  w.gtag?.('event', 'login', {
    method,
  })
  if (!w.gtag || typeof w.gtag !== 'function') {
    devlog('gtag not found, login', w.gtag)
  } else {
    devlog('gtag found, login', w.gtag)
  }
}

export const trackShare = () => {
  if (!GA_MEASUREMENT_ID) return
  const w = window as any
  w.gtag?.('event', 'share', {})
  if (!w.gtag || typeof w.gtag !== 'function') {
    devlog('gtag not found, share', w.gtag)
  } else {
    devlog('gtag found, share', w.gtag)
  }
}

export const pageview = (GA_MEASUREMENT_ID: string, url: string) => {}
