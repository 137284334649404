import { ReferralSummaryData } from '@/app/hooks/useQueryUser'
import { Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { Affiliate, ReferralStatus } from '../FormTypes'
import { asCurrency } from '@/app/utils/stringUtils'
interface DownlineListProps {
  downline?: ReferralSummaryData['downline']
  downlineRate?: ReferralSummaryData['downlineRate']
}

const DownlineList: React.FC<DownlineListProps> = ({ downline, downlineRate }) => {
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'Registered', headerName: 'Registered', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'Applying', headerName: 'Applying', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'Filing', headerName: 'Filing', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'IRS', headerName: 'IRS', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'Closed', headerName: 'Closed', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'Lost', headerName: 'Inelligible', width: 125, align: 'center', headerAlign: 'center' },
    { field: 'Commission', headerName: '2nd Tier Commissions', width: 125, align: 'center', headerAlign: 'center' },
  ]

  const data = useMemo(() => {
    if (!downline) {
      return []
    }
    let _downline: Array<Affiliate> = []
    const names = Object.getOwnPropertyNames(downline)
    names.forEach((name) => {
      const downlineAffiliate = { ...downline[name] }
      downlineAffiliate.Commission = asCurrency(downlineAffiliate.Commission?.toString() ?? '0')
      _downline.push({ name, ...downlineAffiliate })
    })
    return _downline
  }, [downline?.length])


  let rowIndex = -1
  return data.length > 0 ? (
    <div className={'flex flex-1 flex-col'}>
      <DataGrid
        getRowId={(row) => {
          rowIndex++
          return rowIndex
        }}
        disableColumnFilter
        disableColumnMenu
        rows={data}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50]}
      />
    </div>
  ) : (
    <Typography>Your downline is empty.</Typography>
  )
}

export default DownlineList
