import { userTokenAtom } from '@/app/atoms'
import useQueryUser from '@/app/hooks/useQueryUser'
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { title } from 'case'
import { useAtomValue } from 'jotai'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Referral, ReferralStatus } from '../FormTypes'
interface ReferralListProps {
  shouldQuery?: boolean
  data?: Referral[]
}
const referralStatusList = Object.keys(ReferralStatus).filter((val) => isNaN(Number(val)))

const getRandomString = (length = 6) => Math.random().toString(20).substring(2, length)
const getRandomStatus = () => referralStatusList[Math.floor(Math.random() * 4)]

const ReferralList: React.FC<ReferralListProps> = ({ shouldQuery, data }) => {
  const userToken = useAtomValue(userTokenAtom)
  const { getUserReferralList } = useQueryUser()
  // const { data, isLoading, isFetched } = useQuery('referral-list', async () => getUserReferralList(userToken), {
  //   enabled: !!userToken,
  // })

  const columns: GridColDef[] = useMemo(() => {
    return [
      { field: 'last_name', headerName: 'Name', width: 130 },
      { field: 'email', headerName: 'Email', width: 170 },
      { field: 'phone', headerName: 'Phone', width: 170 },
      { field: 'commission_stage', headerName: 'Stage', width: 130 },
      { field: 'funding_method', headerName: 'Funding Method', width: 170 },
      { field: 'affiliate_commission', headerName: 'Est Commission', width: 130 },
    ]
  }, [data?.length])
  const [filter, setFilter] = useState<Referral['commission_stage'] | ''>('')

  const filteredData = useMemo(() => {
    if (!data?.length) {
      return []
    }
    const list = data.map((ref) => ({
      ...ref,
      status: title(ref.commission_stage),
    }))
    if (filter !== '') {
      return list.filter((row) => row.status.toLowerCase() === filter.toLowerCase())
    }
    return list
  }, [filter, data?.length])

  let rowIndex = -1
  return (
    <div className={'flex flex-1 flex-col'}>
      <div className={'flex flex-0 flex-col items-end'}>
        <FormControl>
          <InputLabel>Filter Status</InputLabel>
          <Select value={filter} label="Filter Status" onChange={(e) => setFilter(e.target.value as typeof filter)}>
            <MenuItem value={''}>-</MenuItem>
            <MenuItem value={'Registered'}>Registered</MenuItem>
            <MenuItem value={'Applying'}>Applying</MenuItem>
            <MenuItem value={'Filing'}>Filing</MenuItem>
            <MenuItem value={'IRS'}>IRS</MenuItem>
            <MenuItem value={'Closed'}>Closed</MenuItem>
            <MenuItem value={'Lost'}>Lost</MenuItem>
          </Select>
        </FormControl>
      </div>
      {filteredData.length > 0 ? (
        <DataGrid
          getRowId={(row) => {
            rowIndex++
            return rowIndex
          }}
          disableColumnFilter
          disableColumnMenu
          rows={filteredData}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { page: 0, pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 50]}
        />
      ) : (
        <Typography>You do not have any referrals.</Typography>
      )}
    </div>
  )
}

export default ReferralList
