import { Control, SetFieldValue, UseControllerProps } from 'react-hook-form'
import { GigApplicationStageBuckets } from '../hooks/useQueryUser'
export enum Steps {
  //CMC Application
  'Prequalifier' = -2,
  'AccountInfo' = -1,
  'FinancialInfo' = 0,
  'EnterDates' = 1,
  'TaxInfo' = 2,
  'ReadyForCPA' = 20,
  'SubmittedToCPA' = 21,
  //CPA Prequalification
  'NewSubmission' = 22, //Confirmation from CPA they received it, Can go to 24, 25, 27
  'Disqualified' = 24,
  'WaitingForDocs' = 25,
  'DocumentsInReview' = 26,
  'Prequalified' = 27, //We need get them to pay
  'Paid' = 29,
  //CPA Filing
  'FilingNotStarted' = 30,
  'FilingStarted' = 35,
  'FilingAnxiouslyWaiting' = 34,
  'FilingReadyForReview' = 37,
  'FilingWaitingOn_8879' = 38,
  'FilingSigned' = 39,
  'Efiled' = 40,
  //IRS
  'IRSCompleted' = 45,
  'IRSRejected' = 44,
  'IRSCreditIssued' = 50,
}

export enum GigStages {
  'Income Information' = 1,
  'Days Impacted' = 2,
  'Qualifying Claim' = 3,
  'User Submit Data' = 4,
  'Awaiting Agreement' = 5,
  'Doc Review' = 6,
  'Building Amendment' = 7,
  'Review' = 8,
  'Sign & Submit to IRS' = 9,
  'Awt IRS Confirmation' = 10,
  'Invoice & Collect' = 11,
  'Closed' = 12,
  'Closed - Already Claimed' = 13,
  'Closed - Insufficient SE Income' = 14,
  'Closed - Cannot Acquire Returns' = 15,
}

export enum ReferralStatus {
  'Registered' = 0,
  'Applying' = 1,
  'Filing' = 1,
  'IRS' = 2,
  'Closed' = 3,
  'Lost' = 3
}

export type Referral = {
  id: string
  commission_stage: GigApplicationStageBuckets
  affiliate_commission: number
  last_name: string
  email: string
  phone: string
  funding_method: string
}

export type Affiliate = {
  name: string
  Registered: number
  Applying: number
  Filing: number
  IRS: number
  Closed: number
  Lost: number
  Commission: string
}
export type FormInputProps = {
  control: Control<any>
  name: string
  label?: string
  setValue: SetFieldValue<any>
  helpText?: string
  rules?: UseControllerProps['rules']
  autoComplete?: string
}

export interface UserFormProps {
  onBack?: (userInfo?: UserInfo) => void
  onContinue: (userInfo?: UserInfo) => void
  setErrorState?: (error: string | null) => void
  isPosting?: boolean
}

export type PrequalifierData = {
  selfEmployed: boolean
  positiveNet: boolean
  missedTime: boolean
}

export type RegisterData = {
  firstName: string
  lastName: string
  email: string
  termsAndConditions: boolean
}

export type FinancialInfoData = {
  incomeGreater2019: boolean
  netIncome2019?: number
  netIncome2020?: number
  netIncome2021?: number
  netIncome2019Range: number | null
  netIncome2020Range: number | null
  netIncome2021Range: number | null
}

export type DatesData = {
  covidDates2020?: Array<CovidDateRange>
  covidDates2021?: Array<CovidDateRange>
}

export enum DateSteps {
  dateIntro = -1,
  datesForYourself = 0,
  datesForAnother = 1,
  datesForChild = 2,
  creditSummary = 3,
}

export interface DatesPageProps {
  covidDates2020: Array<CovidDateRange>
  covidDates2021: Array<CovidDateRange>
  updateDates: (args: {
    dates2020?: Array<CovidDateRange>
    dates2021?: Array<CovidDateRange>
    reason: CovidDateRange['reason']
  }) => void
}

export type Info2020Data = {
  incomeGreater2019: boolean
  w2_2020: boolean
  netIncome2019?: number
  netIncome2020?: number
  covidDates2020?: Array<CovidDateRange>
  covidDates2021?: Array<CovidDateRange>
  leavePay2020: number
}

export type Info2021Data = {
  incomeGreater2020: boolean
  w2_2021: boolean
  netIncome2020?: number
  netIncome2021?: number
  covidDates2021?: Array<CovidDateRange>
  leavePay2021: number
}

export type PersonalInfoData = {
  dateOfBirth: string
  nameOfCompany?: string
  industry: string
  street: string
  street2?: string
  city: string
  state: string
  zip: string
}

export type AddressData = {
  street: string
  street2?: string
  city: string
  state: string
  zip: string
}
