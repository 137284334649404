import { FormControl, TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { FormInputProps } from '../FormTypes'

export const FormTextInput: React.FC<
  FormInputProps & TextFieldProps & { stripLeadingZeros?: boolean; isInteger?: boolean }
> = ({
  name,
  autoComplete,
  control,
  label,
  type,
  helperText,
  InputProps,
  rules,
  isInteger = true,
  stripLeadingZeros = true,
  ...fieldProps
}) => {
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error, isDirty } }) => (
        <FormControl error={!!error}>
          <TextField
            name={name}
            autoComplete={autoComplete}
            required={fieldProps.required || !!rules?.required}
            fullWidth
            inputProps={{ value, ...(fieldProps?.inputProps || {}) }}
            value={value}
            error={!!error}
            type={type}
            size="small"
            helperText={error?.message ?? helperText}
            label={label}
            InputProps={InputProps}
            onFocus={(e) => {
              if (type === 'number') {
                if (!isDirty) {
                  onChange('')
                }
              }
            }}
            onChange={(e) => {
              let val = e.target.value
              if (type === 'number') {
                if (['NaN', ''].includes(val) || parseInt(val) === 0) {
                  onChange('')
                } else {
                  onChange(isInteger ? parseInt(val) : Number(val))
                }
              } else {
                onChange(val)
              }
            }}
            onBlur={() => {
              if (value === '' && type === 'number') onChange(0)
              onBlur()
            }}
          />
        </FormControl>
      )}
    />
  )
}
