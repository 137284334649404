'use client'
import { userTokenAtom } from '@/app/atoms'
import useClipboard from '@/app/hooks/useCliipboard'
import useQueryUser, { ReferralSummaryData, Stats } from '@/app/hooks/useQueryUser'
import { devlog } from '@/app/utils'
import { Box, Button, Card, Grid, Snackbar, Stack, Typography } from '@mui/material'

import { asCurrency } from '@/app/utils/stringUtils'
import { useAtomValue } from 'jotai'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { FormInputCheckbox } from '../formComponents/FormCheckbox'
import DataGridComponent from './DataGridComponent'
import DownlineList from './DownlineList'
import ReferralList from './ReferralList'
import './dashboard.css'

export const jobList = [
  '1099 Employee',
  'Independent Contractors',
  'Influencers',
  'Freelancers',
  'Subcontractors',
  'Consultants',
  'Ecommerce Pros',
  'Real Estate Agents',
  'Bookkeepers',
  'Photographers',
  'Designers',
  'Amazon Sellers',
  'Accountants',
  'Veterinarians',
  'Air BnB Hosts',
  'Doordashers',
  'Uber Drivers',
  'Task Rabbit',
  'Owner/Operators',
]

enum ReferralTabs {
  'Summary',
  'Referrals',
  'Downline',
}

export function ReferralSection({ trackShare }: { trackShare: () => void }) {
  const userToken = useAtomValue(userTokenAtom)
  const clipboard = useClipboard()
  const [copySnackOpen, setCopySnackOpen] = useState(false)
  const { getUserReferralSummary, getVisitorActivity } = useQueryUser()
  const [showAllJobs, setShowAllJobs] = useState(false)

  const {
    handleSubmit,
    reset,
    formState: { errors },
    ...formMethods
  } = useForm<{ termsAndConditions: boolean }>({
    defaultValues: {
      termsAndConditions: false,
    },
    mode: 'onChange',
  })

  const { data, dataUpdatedAt, refetch } = useQuery<QueryResponse<ReferralSummaryData>>(
    'referral-summary',
    async () => getUserReferralSummary(userToken),
    {
      enabled: !!userToken,
    }
  )

  const summary = data?.body

  const refCodePair = summary?.referralUrl?.split('?')?.[1]
  const refCode = refCodePair?.split('=')?.[1]

  const visitorActivityState = useQuery<QueryResponse<VisitorActivityData>>(
    'visitor-activity',
    async () => getVisitorActivity(refCode ?? ''),
    {
      enabled: !!refCode,
    }
  )

  const enableSubAffiliate = summary?.enableSubAffiliate
  const hasDownline = enableSubAffiliate && Object.getOwnPropertyNames(summary?.downline ?? []).length > 0

  const downlineSums = useMemo(() => {
    let CommissionNum: number | string = 0
    const zeroedOutStats: Omit<Stats, 'Commission'> = {
      Registered: 0,
      Applying: 0,
      Filing: 0,
      IRS: 0,
      Closed: 0,
      Lost: 0,
    }

    if (!summary?.downline) {
      ;(zeroedOutStats as Stats).Commission = '$0'
      return zeroedOutStats
    }
    summary?.downline

    let stats: Omit<Stats, 'Commission'> = zeroedOutStats
    const names = Object.getOwnPropertyNames(summary?.downline)
    names.forEach((name) => {
      const affiliateObj = summary?.downline[name]
      stats.Registered += affiliateObj.Registered
      stats.Applying += affiliateObj.Applying
      stats.Filing += affiliateObj.Filing
      stats.IRS += affiliateObj.IRS
      stats.Closed += affiliateObj.Closed
      stats.Lost += affiliateObj.Lost
      CommissionNum += affiliateObj.Commission
    })

    const commissionStr = asCurrency(CommissionNum.toString() ?? '0')

    return {
      ...stats,
      Commission: commissionStr,
    } as Stats
  }, [hasDownline, summary?.downline])

  const totalCount = Object.keys(summary?.summary || {}).reduce(
    (total, key) => total + ((summary?.summary as any)?.[key] || 0),
    0
  )

  const acceptTermsState = useMutation<
    { success: boolean; error: string | null; termsAndConditions?: boolean },
    void,
    { termsAndConditions: boolean }
  >(
    async () => {
      let queryUrl = `${process.env.NEXT_PUBLIC_WORKER}/referral/accept`
      try {
        const response = await fetch(queryUrl, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        })
        const data = await response?.json?.()
        if (!!data) {
          devlog('ReferralSection >> accept Terms')
          return { success: true, error: null, termsAndConditions: data.terms_accepted }
        }

        devlog(
          'ReferralSection >> accept terms NOT response.ok',
          JSON.stringify(
            {
              response,
              userToken,
            },
            null,
            2
          )
        )
        return { success: false, body: null, error: 'NOT response.ok' }
      } catch (e) {
        return { success: false, body: null, error: JSON.stringify(e) }
      }
    },
    {
      onSuccess: (data) => {
        refetch()
      },
    }
  )

  const onSubmitTerms = (data: { termsAndConditions: boolean }) => {
    if (data.termsAndConditions) {
      acceptTermsState.mutate(data)
    }
  }

  // one card, three tabs
  // summary
  // referrals
  // downline (if present)
  if (!summary) {
    return null
  }

  if (!summary.terms_accepted) {
    return (
      <div className="flex flex-col justify-center items-center flex-1">
        <Card className="flex flex-col justify-start items-center p-2 md:p-8">
          <div className="flex justify-center items-center text-xl font-medium">Accept Agreement to continue</div>
          <div className="h-8" />
          <div style={{ maxHeight: 500 }} className="border border-solid border-gray-400 flex flex-col py-2">
            <div className="flex-1 overflow-y-scroll px-2">
              {agreementText(summary.referralAmountPerApp, summary.referralRateType)
                .split('\n')
                .map((line, i) => {
                  const isHeader = !line.includes(' ') || line.toUpperCase() === line
                  return (
                    <p
                      className={`${isHeader ? 'font-semibold text-center' : 'text-start'}`}
                      style={{ textIndent: isHeader ? 0 : 40 }}
                      key={i}>
                      {!isHeader ? '\t' : ''}
                      {line}
                    </p>
                  )
                })}
            </div>
          </div>
          <div className="h-8" />
          <div>
            <FormInputCheckbox
              rules={{ required: 'You must accept the Agreement to continue' }}
              name="termsAndConditions"
              label="Accept Agreement"
              {...formMethods}
            />
          </div>
          <div className="h-8" />
          <Box className="bottom-buttons" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmitTerms)}
              disabled={acceptTermsState?.isLoading}
              className="w-48">
              Continue
            </Button>
          </Box>
        </Card>
      </div>
    )
  }

  const stats = visitorActivityState?.data?.body?.stats
  const totalCurrencyStr = summary?.commissionStats?.total_commission_earned_to_date?.toString()
  const totalCurrency = asCurrency(totalCurrencyStr)
  const validAppsTowardTiers = totalCount - (summary?.summary?.Registered ?? 0)

  return (
    <div
      className="mainWrapper"
      style={{
        background: 'linear-gradient(120deg, #03040a 0%, #668573 100%)',
        paddingTop: '44px',
        display: 'flex',
        width: '100vw',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}>
      <div
        style={{
          placeContent: 'flex-start',
          alignItems: 'flex-start',
          backgroundColor: 'rgb(0, 0, 0)',
          borderRadius: '32px',
          display: 'flex',
          flex: '0 0 auto',
          flexFlow: 'column',
          gap: '18px',
          height: 'min-content',
          maxWidth: '1440px',
          overflow: 'visible',
          padding: '20px 32px 60px',
          position: 'relative',
          width: '100%',
        }}>
        <div>
          <h1 style={{ color: '#fff', fontSize: '50px', fontWeight: 200 }}>
            Welcome,{' '}
            <span style={{ color: '#b0e5c7' }}>
              {summary?.affiliate?.first_name + ' ' + summary?.affiliate?.last_name}
            </span>
            , to your affiliate <span style={{ color: '#b0e5c7' }}>dashboard</span>
          </h1>
          <h4 style={{ color: '#fff', fontWeight: 200, maxWidth: 950 }}>
            We are excited to have you on board, and dedicated to giving you tools to help your network succeed.
          </h4>
        </div>
        <div className="flex flex-col w-full">
          <div className={'card_wrapper'}>
            {summary?.referralCode ? (
              <LinksSection
                setCopySnackOpen={setCopySnackOpen}
                trackShare={trackShare}
                hasDownline={enableSubAffiliate}
                refCode={summary?.referralCode}
              />
            ) : null}
            <div className="h-16" />

            <WhatsWorkingSection />
            <div className="h-16" />

            {stats?.activityAllTime?.[0] ? <VisitorActivityData stats={stats} /> : null}
            <div className="h-24" />

            <div className={`flex flex-1 flex-col items-stretch`}>
              <div className={'flex flex-1 flex-col items-stretch'}>
                <h6 className="text-center mb-3 text-white">* This section is only updated once per day</h6>
                <Card
                  className={`flex flex-col justify-start items-stretch border border-solid border-gray-300`}
                  style={{
                    minWidth: 350,
                    minHeight: Math.min(250 + totalCount * 50, 800),
                  }}>
                  <div className="flex-1">
                    <div className="flex-1 w-full">
                      <div>
                        <h1 className="text-center">Referred Applications</h1>
                        <h2 className="text-center">Totals</h2>
                        <ReferralStatsSection summary={summary?.summary} totalCount={totalCount} />
                      </div>
                    </div>
                  </div>
                  <div className="h-4" />
                  <h6 className="text-center mb-3 text-white">* This section is only updated once per day</h6>
                  <div className="flex-1">
                    <div className="flex-1 w-full">
                      <h2 className="text-center">Individual Applications</h2>
                      <div>
                        <ReferralList shouldQuery={false} data={summary?.referralList} />
                      </div>
                      <div className="h-8" />
                      <div className="font-bold text-4xl text-black text-center">
                        Total Commission to date: {totalCurrency}
                      </div>
                      <div className="font-bold text-xl text-black text-center">
                        Your {summary.referralRateType === 'growth' ? 'Current ' : ''}Commission Rate:{' '}
                        {summary?.affiliate?.referral_rate}%
                      </div>
                      <div className={'flex-1 grid grid-cols-4 md:grid-cols-4 justify-items-center items-center'}>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Total Paid</div>
                          <div className="font-bold text-3xl text-black">
                            {asCurrency(summary?.commissionStats?.total_paid_commission?.toString())}
                          </div>
                        </div>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Total Unpaid</div>
                          <div className="font-bold text-3xl text-black">
                            {asCurrency(summary?.commissionStats?.total_unpaid_commission?.toString())}
                          </div>
                        </div>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Unpaid & Ready</div>
                          <div className="font-bold text-3xl text-black">
                            {asCurrency(
                              summary?.commissionStats?.total_unpaid_commission_eligible_for_payment?.toString()
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
                          <div className="font-bold text-base text-black">Unpaid & Not Ready</div>
                          <div className="font-bold text-3xl text-black">
                            {asCurrency(
                              summary?.commissionStats?.total_unpaid_commission_ineligible_for_payment?.toString()
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className="h-4" />
                {hasDownline ? (
                  <Card
                    className={`flex flex-col justify-start items-stretch border border-solid border-gray-300`}
                    style={{
                      minWidth: 350,
                      minHeight: Math.min(250 + totalCount * 50, 800),
                    }}>
                    <div className="flex-1">
                      <div className="flex-1 w-full">
                        <h4 className="text-center">Sub-Affiliates</h4>
                        <div>
                          <DownlineList downline={summary.downline} downlineRate={summary.downlineRate} />
                        </div>
                        <h4 className="text-center">Sub-Affiliate Totals</h4>
                        <ReferralStatsSection summary={downlineSums} />
                      </div>
                    </div>
                  </Card>
                ) : null}
              </div>
            </div>

            <div className="h-24" />
            <Card>
              {summary.referralRateType === 'growth' ? (
                <>
                  <h4 className="text-center mb-2">Earning Tiers</h4>
                  <Card className="p-4">
                    <div className="grid grid-cols-2 gap-y-2">
                      <span>Applicants</span>
                      <span>Commission</span>
                      <span>0-9</span>
                      <span>2.00%</span>
                      <span>10</span>
                      <span>2.50%</span>
                      <span>50</span>
                      <span>2.75%</span>
                      <span>200</span>
                      <span>3.00%</span>
                      <span>750</span>
                      <span>3.25%</span>
                      <span>2,000</span>
                      <span>3.50%</span>
                      <span>5,000</span>
                      <span>3.75%</span>
                      <span>10,000</span>
                      <span>4.00%</span>
                    </div>
                    <div className="h-4" />
                    <div className="font-bold text-xl text-black text-center">
                      Applicants Referred: {validAppsTowardTiers}
                    </div>
                    <div className="font-bold text-xl text-black text-center">
                      Your {summary.referralRateType === 'growth' ? 'Current ' : ''}Commission Rate:{' '}
                      {summary?.affiliate?.referral_rate}%
                    </div>
                  </Card>
                </>
              ) : null}
            </Card>


            <div className="inner_card_wrapper">
              <DataGridComponent />
            </div>

            <div className="h-16" />

            <Snackbar
              open={copySnackOpen}
              autoHideDuration={4000}
              onClose={() => setCopySnackOpen(false)}
              message="Referral URL copied to your clipboard!"
            />
          </div>
        </div>
      </div>
    </div>
    //
  )
}

function ReferralStatsSection(props: {
  summary?: {
    Registered: number
    Applying: number
    Filing: number
    IRS: number
    Closed: number
    Lost: number
    Commission?: string
  }
  totalCount?: number
}) {
  const { totalCount, summary } = props

  return (
    <div className={'grid grid-cols-4 md:grid-cols-7 justify-items-center items-center'}>
      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
        <div className="font-bold text-base text-black">Registered</div>
        <div className="font-bold text-5xl text-black">{summary?.Registered}</div>
      </div>
      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
        <div className="font-bold text-base text-black">Applying</div>
        <div className="font-bold text-5xl text-black">{summary?.Applying}</div>
      </div>
      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
        <div className="font-bold text-base text-black">Filing</div>
        <div className="font-bold text-5xl text-black">{summary?.Filing}</div>
      </div>
      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
        <div className="font-bold text-base text-black">IRS</div>
        <div className="font-bold text-5xl text-black">{summary?.IRS}</div>
      </div>
      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
        <div className="font-bold text-base text-black">Closed</div>
        <div className="font-bold text-5xl text-black">{summary?.Closed}</div>
      </div>
      <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
        <div className="font-bold text-base text-black">Inelligible</div>
        <div className="font-bold text-5xl text-black">{summary?.Lost}</div>
      </div>
      {totalCount ? (
        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
          <div className="font-bold text-base text-black">Total</div>
          <div className="font-bold text-5xl text-black">{totalCount}</div>
        </div>
      ) : null}
      {summary?.Commission ? (
        <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
          <div className="font-bold text-base text-black">Commissions</div>
          <div className="font-bold text-5xl text-black">{summary?.Commission}</div>
        </div>
      ) : null}
    </div>
  )
}

function VisitorActivityData(props: { stats: any }) {
  const { stats } = props
  return (
    <Card className={`flex flex-col justify-start items-stretch border border-solid border-gray-300 mb-8`} style={{}}>
      <div className="flex-1 flex flex-col">
        <h4 className="text-center">Visitor Activity</h4>
        <div className={'grid grid-cols-2 md:grid-cols-5 justify-items-center flex-1 flex-col'}>
          <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
            <div className="font-bold text-base text-black">Total Views</div>
            <div className="font-bold text-5xl text-black">
              {stats.totalActivity?.find((activ: { type: string }) => activ.type === 'view')?.count}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
            <div className="font-bold text-base text-black">Total Clicks</div>
            <div className="font-bold text-5xl text-black">
              {stats.totalActivity?.find((activ: { type: string }) => activ.type === 'click')?.count}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
            <div className="font-bold text-base text-black">Total Unique views</div>
            <div className="font-bold text-5xl text-black">{stats.totalUniqueViews}</div>
          </div>
          <div className="flex flex-col justify-center items-center min-h-36 min-w-36 max-w-36 max-h-36">
            <div className="font-bold text-base text-black">Total Unique clicks</div>
            <div className="font-bold text-5xl text-black">{stats.totalUniqueClicks}</div>
          </div>
        </div>
        <div>
          <div className="flex flex-col justify-start items-stretch px-4 mb-8">
            <div className="font-bold text-base text-black">activityAllTime</div>
            <div className={`grid grid-cols-3 gap-4`}>
              {Object.keys(stats.activityAllTime?.[0])?.map((key) => (
                <div className="bg-gray-100 p-2" key={key}>
                  <span>{key}</span>
                </div>
              ))}
              {stats.activityAllTime?.map((stat: { [s: string]: unknown } | ArrayLike<unknown>) => (
                <>{Object.values(stat)?.map((val: any, idx) => <span key={`${val}${idx}`}>{val}</span>)}</>
              ))}
            </div>
          </div>
          <div className="flex flex-col justify-start items-stretch px-4 mb-8">
            <div className="font-bold text-base text-black">activityByDay</div>
            <div className={`grid grid-cols-4 gap-4`}>
              {Object.keys(stats.activityByDay?.[0])?.map((key) => (
                <div className="bg-gray-100 p-2" key={key}>
                  <span>{key}</span>
                </div>
              ))}
              {stats.activityByDay?.map((stat: { [s: string]: unknown } | ArrayLike<unknown>) => (
                <>{Object.values(stat)?.map((val: any, idx) => <span key={val?.toString() + idx}>{val}</span>)}</>
              ))}
            </div>
          </div>
          {/* <div className="flex flex-col justify-start items-stretch px-4 mb-8">
              <div className="font-bold text-base text-black">activityByHour</div>
              <div className={`grid grid-cols-3 gap-4`}>
                {Object.keys(stats.activityByHour?.[0])?.map((key) => (
                  <div className="bg-gray-100 p-2" key={key}>
                    <span>{key}</span>
                  </div>
                ))}
                {stats.activityByHour?.map((stat) => (
                  <>
                    {Object.values(stat)?.map((val, idx) => {
                      const localTime = dayjs(val).add(dayjs(val).utcOffset(), 'minutes')
                      return (
                        <span key={val?.toString() + idx}>
                          {idx === 1
                            ? localTime.format('MMM DD') +
                              ` between ${localTime.hour()}:00-${localTime.hour() + 1}:00`
                            : val}
                        </span>
                      )
                    })}
                  </>
                ))}
              </div>
            </div> */}
        </div>
      </div>
    </Card>
  )
}

function WhatsWorkingSection() {
  return (
    <Card className="p-4 py-8">
      <div className="font-bold text-4xl text-black">Whats Working Now</div>
      <div className="text-base">
        Here is a breakdown of what types of promotions and marketing is currently performing the best for our SETC
        Affiliates. Remember this is a time sensitive opportunity that ends April 15, 2025. Get your promotions going
        now!
      </div>
      <div className="text-xl font-semibold text-slate-700 mt-6">Direct/Word Of Mouth Referrals</div>
      <div className="text-base">
        The old tried and true method is still alive and while it&apos;s a bit labor intensive, telling people in your direct
        network about SETC personally is massively effective. We’re not saying contact everyone individually, but
        certainly your close friends, vendors and family would be worth a personal reach out.
      </div>
      <div className="text-xl font-semibold text-slate-700 mt-6">Email Promotion</div>
      <div className="text-base">
        If you have an email list, then sending out a structured email promotion is highly effective. This is the #1
        promotional method our affiliates are using to promote SETC. But they are not winning by sending out a ‘single’
        email. They are winning by sending out muti-email structured promotions. These promotions consist of 3 to 4
        emails over a 4 to 8 day period sent in sequence with each email hitting different angles and hooks and
        providing different links to SETC assets. If you need help with email promo ideas, check out the “SETC Promo
        Emails” PDF in the resources section below.
      </div>
      <div className="text-xl font-semibold text-slate-700 mt-6">Social Media Promotion</div>
      <div className="text-base">
        If you have a social media presence and/or following this is a great way to spread the word about SETC. Static
        posts are working well, but videos are by far working the best. Again posting more than once using different
        angles and hooks is making the biggest difference. Also, several affiliates are making static posts and/or video
        posts and then linking out to a google doc or blog post that contains more information about SETC that readers
        can then learn and click through from.
      </div>
      <div className="text-xl font-semibold text-slate-700 mt-6">Blog Posts/SEO</div>
      <div className="text-base">
        If you have an established website or blog, SETC is a goldmine of low hanging fruit from a search engine
        optimization perspective. “SETC” and its related keywords are not a term that people are fighting to rank over,
        so it is insanely easy to rank SETC related pages fast and high in the search engines.
      </div>
      <div className="text-xl font-semibold text-slate-700 mt-6">Live Webinar Promotion</div>
      <div className="text-base">
        If you can put 100 people on a webinar (gotomeeting, zoom, ect) live...meaning 100 show up and are on the call
        live, we can schedule a private webinar promotion with you where our team will present a custom tailored SETC
        webinar live to your audience. You will get credit for all applications generated from the webinar, and would
        also receive a hosted replay page that you can continue to promote. Live webinars convert higher than any other
        promotional method. You can view a version of the webinar here:{' '}
        <a href="https://www.setccredit.org/webclass">https://www.setccredit.org/webclass</a>
      </div>
      <br/>
      <div className="text-base">
        Please contact us if you&apos;d like to schedule a live webinar. Please note that all promotional aspects of the
        webinar to your audience are your responsibility. Typically a webinar show up rate is about 25% of the total
        registrations. Meaning that 1 in every 4 people who register will show up live on average.
      </div>
      <div className="text-xl font-semibold text-slate-700 mt-6">Multi-Angle, Multi Platform Approach</div>
      <div className="text-base">
        Our best affiliates are promoting SETC in multiple different ways and on multiple different platforms/channels.
        The more people see it, the more people are likely to act on it. Additionally, talking about SETC in different
        ways and showing different information relating to it makes a big difference. The highest converting affiliates
        are using all the different direct affiliate links in conjunction with their promotions.
      </div>
      <div className="text-xl font-semibold text-slate-700 mt-6">Repeated Promotion</div>
      <div className="text-base">
        This is not a one and done promotion. The affiliates making the biggest impact and the most commissions are
        promoting SETC repeatedly every few weeks. Remember on average it takes 7 to 12 touchpoints before someone
        starts paying attention to something and we all know the fortune is in the follow up. SETC is no different. So,
        for best results do a “refresher” promotion every few weeks to keep SETC top of mind with your audience. The
        better you do that, the more of them will claim their refunds. If you’ve already done a promotion, check out the
        new links and resources we are constantly adding to give you new angles and ways to promote.
      </div>
    </Card>
  )
}

function LinksSection(props: {
  refCode: string
  setCopySnackOpen: React.Dispatch<React.SetStateAction<boolean>>
  trackShare: () => void
  hasDownline: boolean | undefined
}) {
  const { refCode, setCopySnackOpen, trackShare, hasDownline } = props
  const clipboard = useClipboard()
  const refUrl = `https://setccredit.org/?source=${refCode}`
  const setc101Url = `https://setccredit.org/blog-details/the-complete-guide-to-the-self-employed-tax-credit-(setc)?source=${refCode}`
  const webclassUrl = `https://www.setccredit.org/webclass?source=${refCode}`
  const affiliateRefUrl = hasDownline ? `https://setccredit.org/join?source=${refCode}` : null

  return (
    <>
      <Card className="inner_card_wrapper" style={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="mb-2" align="left" variant="h3">
              Your Unique Referral Links:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <div className="sub_heading_wrapper">
              <div className="sub_heading_inner_wrapper">
                <Typography className="mb-2 text-black" align="left" variant="subtitle1">
                  Here is your unique referral information! 🥳
                </Typography>
                <Typography className="mb-2 text-black" align="left" variant="subtitle1">
                  Share with your network and we&apos;ll track your success every step of the way!
                </Typography>
              </div>
              <h4 className="sub_heading"></h4>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography className="mb-1 text-black" align="left" variant="subtitle1">
              Marketing Site link is:
            </Typography>
          </Grid>

          <Grid item xs={12} mb={2}>
            <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 4 }}>
              <Box
                bgcolor={'#f2f2f2'}
                className="flex-1 flex justify-start items-center text-sm md:text-sm text-black cursor-auto hover:cursor-pointer px-2"
                onClick={() => {
                  clipboard.copy(refUrl)
                  setCopySnackOpen(true)
                  trackShare()
                }}>
                {refUrl}
              </Box>
              <Button
                onClick={() => {
                  clipboard.copy(refUrl)
                  setCopySnackOpen(true)
                  trackShare()
                }}
                variant="contained"
                className="px-4">
                Copy
              </Button>
            </Stack>
            <Grid item xs={12} lg={9}>
              <Typography variant={'body2'} mt={2}>
                We continue to optimize our marketing pages to provide clients with the best information about our
                offer.
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} mt={4}>
            <Typography className="mb-1 text-black" align="left" variant="subtitle1">
              SETC 101 Link:
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 4 }}>
              <Box
                bgcolor={'#f2f2f2'}
                className="flex-1 flex justify-start items-center text-sm md:text-sm text-black cursor-auto hover:cursor-pointer px-2"
                onClick={() => {
                  clipboard.copy(setc101Url)
                  setCopySnackOpen(true)
                  trackShare()
                }}>
                {setc101Url}
              </Box>
              <Button
                onClick={() => {
                  clipboard.copy(setc101Url)
                  setCopySnackOpen(true)
                  trackShare()
                }}
                variant="contained"
                className="px-4">
                Copy
              </Button>
            </Stack>
            <Grid item xs={12} lg={9}>
              <Typography variant={'body2'} mt={2}>
                A link to a our setc 101 page to help your clients learn more about the SETC credit
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography className="mb-1 text-black" align="left" variant="subtitle1">
              Webclass Link:
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2}>
            <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 4 }}>
              <Box
                bgcolor={'#f2f2f2'}
                className="flex-1 flex justify-start items-center text-sm md:text-sm text-black cursor-auto hover:cursor-pointer px-2"
                onClick={() => {
                  clipboard.copy(webclassUrl)
                  setCopySnackOpen(true)
                  trackShare()
                }}>
                {webclassUrl}
              </Box>
              <Button
                onClick={() => {
                  clipboard.copy(webclassUrl)
                  setCopySnackOpen(true)
                  trackShare()
                }}
                variant="contained"
                className="px-4">
                Copy
              </Button>
            </Stack>
            <Grid item xs={12} lg={9}>
              <Typography variant={'body2'} mt={2}>
                A link to a our webclass to share with those that really want to dive in to learn more about the SETC
                credit
              </Typography>
            </Grid>
          </Grid>
          {hasDownline ? (
            <>
              <Grid item xs={12} mt={4}>
                <Typography className="mb-1 text-black" align="left" variant="subtitle1">
                  Broker Link:
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Stack direction={'row'} spacing={{ xs: 1, sm: 2, md: 4 }}>
                  <Box
                    bgcolor={'#f2f2f2'}
                    className="flex-1 flex justify-start items-center text-sm md:text-sm text-black cursor-auto hover:cursor-pointer px-2"
                    onClick={() => {
                      clipboard.copy(affiliateRefUrl)
                      setCopySnackOpen(true)
                      trackShare()
                    }}>
                    {affiliateRefUrl}
                  </Box>
                  <Button
                    onClick={() => {
                      clipboard.copy(affiliateRefUrl)
                      setCopySnackOpen(true)
                      trackShare()
                    }}
                    variant="contained"
                    className="px-4">
                    Copy
                  </Button>
                </Stack>
                <Grid item xs={12} lg={9}>
                  <Typography variant={'body2'} mt={2}>
                    A link to refer sub-affiliates
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Card>
    </>
  )
}

function formatRate(rate: number, type: 'percent' | 'flat' | 'growth') {
  if (type === 'percent' || type === 'growth') return `${rate}%`
  return `$${rate}`
}

const agreementText = (rate = 100, rateType: 'percent' | 'flat' | 'growth') => `
AFFILIATE AGREEMENT

1. Services. Affiliate (You) agrees to the following responsibilities and to provide services as follows
(“Services”):
1.1. Affiliate may bring potential clients to Company as opportunities for Company and its processor to file the FFCRA Self Employment Tax Credit on their behalf

2. Affiliate Personnel. Affiliate shall ensure that any and all of its representatives, employees, agents, staff, and subcontractors (“Personnel”) shall adhere to the terms described herein. In all cases, Affiliate shall conform to state and federal labor laws with regard to its Personnel. Affiliate expressly agrees to indemnify and hold Company harmless from any and all liability whatsoever associated therewith

Affiliate shall bear any and all costs or expenses incurred by Affiliate and/or its Personnel to perform the Services herein, including without limitation, insurance, travel expenses, mileage, office expenses, telephone, labor costs, and all related costs, if any. Affiliate will ensure that it, and all Personnel, shall have any and all insurance as required by law, including without limitation, worker’s compensation insurance

3. Independent Contractor Status; Relationship of the Parties. Affiliate represents and agrees that for State or Federal Income Tax purposes, Affiliate will be treated, paid and classified as an independent contractor, and therefore, Affiliate is solely responsible for the timely payment of all taxes for any amounts paid to Affiliate under this Agreement including, but not limited to, all federal, state, or local taxes

Affiliate further represents and agrees that the Company is under no obligation to withhold any amounts for taxes for Affiliate nor to inform Affiliate of any tax obligations, prepare any tax reports, or transfer any amounts for taxes. No part of Affiliate’s compensation will be subject to withholding by the Company for the payment of any social security, federal, state or any other employee payroll taxes

The Company will regularly report amounts paid to Affiliate as required by law

Except as otherwise provided, in no event shall the Company be liable in any manner for the acts and omissions of Affiliate or its Personnel including, without limitation, the failure of Affiliate to compensate its Personnel. In performance of its obligations and Services under this Agreement, Affiliate shall act and be contracted solely as an independent contractor of the Company. It is not the intent of the Parties to create under this Agreement a joint venture, partnership, business association or other form of business entity. Affiliate shall be free to perform services for other parties

4. Compensation

4.1. Affiliate shall be paid

Your commission rate shall be displayed in the dashboard

4.2. Affiliate shall be paid weekly the week following the receipt of total payment by the client

5. No Worker’s Compensation or Unemployment Insurance Benefits. Affiliate acknowledges and agrees, that as an independent contractor, neither it, nor its Personnel, are entitled to workers’ compensation coverage from the Company or unemployment insurance coverage from the Company and should an injury or period of unemployment occur during its performance of services under this Agreement, such claims will not be covered by the Company’s workers’ compensation policy or any program providing unemployment insurance benefits

Affiliate agrees to complete and sign any additional document required as further evidence of the fact that Affiliate has been engaged solely as an independent contractor and is not entitled to workers’ compensation coverage from the Company or unemployment insurance benefits

6. Work Materials. Affiliate is responsible to provide all necessary materials, including without limitation, a device such as a computer, tablet, or smartphone to access the Company’s portal, and to perform the Services

7. Code of Conduct. Affiliate agrees to be completely honest and ethical in all of its dealings with the Company, its affiliates, clients, customers, and government agencies. If any person complains to the Company because of Affiliate’s alleged dishonest, unethical, or improper conduct, the Company may terminate this Agreement, and Affiliate shall be liable and responsible to the Company for any and all fines, damages, attorneys’ fees and or costs incurred as a result of Affiliate’s actions or fraud. Affiliate agrees to comply with all laws, rules, regulations, or ordinances applicable to the Services that Affiliate performs under this Agreement and that Affiliate will review and make itself and its Personnel aware of said laws, rules, regulations, or ordinances before performing any services for the Company under this Agreement. ANY FRAUD OR CRIMINAL MISCONDUCT BY AFFILIATE WILL BE REPORTED TO LAW ENFORCEMENT. AFFILIATE UNDERSTANDS AND AGREES THAT IT WILL BE LIABLE TO THE COMPANY FOR ALL DAMAGE FROM FRAUD, FOR ALL NEGLIGENT ACTS, RECKLESS BEHAVIOR OR INTENTIONAL MISCONDUCT, WHETHER CRIMINAL OR CIVIL. Any misrepresentation as a result of dishonesty or unethical behavior may be considered sufficient cause for termination of this Agreement and other consequences as deemed necessary to remedy resulting damage. Termination of this Agreement will result in a loss of any unpaid or unearned Compensation

8. Termination. Affiliate agrees that this Agreement may be terminated as follows:
8.1. Termination. Affiliate may terminate this Agreement per the terms specified in this section at any time by 30 days’ written notice to the other Party

8.2. Compensation Pay on Termination. If Affiliate terminates this Agreement for any reason, then Affiliate will be entitled to any earned and unpaid Compensation from Clients it obtained up to and including the date of termination according to the Compensation terms of this Agreement, provided, however, that in the event Affiliate violates the preceding section herein concerning Code of Conduct, then Affiliate shall only be entitled to Compensation earned up to the time of the violation and shall not be entitled to any Compensation for Services performed after the time of the violation

9. Choice of Law, Jurisdiction and Venue. The Parties agree that this Agreement shall be deemed to have been made and entered into in the State of Utah and that the law of the State of Utah shall govern this Agreement. Subject to the Agreement regarding arbitration, any unresolved disputes arising from this Agreement shall be adjudicated in the District Court of the State of Utah

10. Confidentiality

10.1. Proprietary Rights. The Company reserves and maintains ownership of all copyrights, trademarks, designs, artwork, verbal affiliations, and visual affiliations, with regard to the content and representation of the Company

Any and all information in relation to marketing, sales, processing, accounting, and ideas of the Company’s services is strictly confidential and representation or claims, other than those authorized by the Company, made by Affiliate is strictly prohibited

10.2. Affiliate hereby recognize that the Company has developed and will continue to develop a substantial business, which includes the ownership and possession of Intellectual Property and Proprietary Structures and Systems, numerous procedures, technologies, files and client lists and other confidential information regarding clients and projects (“Confidential Information”), all of which are confidential in nature and which derive independent economic value, and actual potential from not being generally known to, and not being readily ascertainable through proper means by, other persons who can obtain economic value from its disclosure all of which are and will continue to be of great and unique value to the Company, and which the Company will make reasonable efforts to maintain its secrecy and that by reason of Affiliate’s relationship with the Company, Affiliate may acquire such Confidential Information regarding the clients and projects of the Company. The Company has developed an exclusive market for its products and has spent, and will continue to spend, considerable time in marketing its products nationally

10.3. In consideration of the Company’s disclosure of Confidential Information to Affiliate, it hereby agrees as follows:
10.3.1. Affiliate shall hold and maintain the Confidential Information in strictest confidence and in trust for the sole and exclusive benefit of the Company

10.3.2. Affiliate shall not, without the prior written approval of the Company, use for its own benefit, publish or otherwise disclose to others (excluding its Personnel), or permit the use by others (excluding its Personnel) for their benefit or to the detriment of the Company, any of the Confidential Information, unless required by law

10.3.3. Affiliate shall carefully restrict access to Confidential Information and shall allow access only to those who clearly require it in order to fulfill the obligations to the Company hereunder. Affiliate further warrants and represents that it shall advise each person to whom they provide access to any of the Confidential Information under the foregoing sentence that such persons are strictly prohibited from making any use, publishing or otherwise disclosing, or permitting others to use for their benefit or to the detriment of the Company, any of the Confidential Information

10.3.4. Affiliate shall take all reasonable and necessary action to protect the confidentiality of the Confidential Information, except for the necessary disclosure under the preceding Paragraph, and agrees to indemnify the Company against any and all losses, damages, claims or expenses incurred or suffered by the Company as a result of Affiliate’s breach of this Agreement

10.3.5. The Company has a legitimate and protectable interest in its Intellectual Property and Confidential Information. It is possible that

Affiliate may draw away customers from the Company if Affiliate was permitted to compete or act in any manner adverse to the Company. The Company desires to prohibit Affiliate from divulging to others, representing, or using for Affiliate’s own benefit Proprietary Structures and Systems, clients, customers, products, technologies, processes, or matters associated with said business of the Company which Affiliate learns as a result of Affiliate’s relationship with the Company. The Company desires to prohibit Affiliate unauthorized competition with said business of the Company, and Affiliate agrees with the Company as to the proprietary nature and confidentiality of the information regarding the clients, customers, products, processes and other matters of the Company, and as to the necessity for the Company prohibiting Affiliate unauthorized competition with the Company’s business;
10.3.6. Affiliate understands and acknowledges that any disclosure or misappropriation of any of the Confidential Information in violation of this Agreement may cause the Company irreparable harm, the amount of which may be difficult to ascertain and, therefore, agrees that the Company shall have the right to apply to a court of competent jurisdiction for an order restraining any such further disclosure or misappropriation and for such other relief as the Company shall deem appropriate. Such right of the Company is to be in addition to the remedies otherwise available to the Company at law or in equity

10.4. Affiliate shall return to the Company any and all records, notes, and other written, printed or tangible materials pertaining to the Confidential Information immediately upon the termination of this Agreement

11. Non-circumvention

11.1. Affiliate acknowledges and agrees that any contract for potential business including but not limited to; Services, potential vendors, vendors, potential clients, clients, potential suppliers, and suppliers that the Company represents to Affiliate shall not be approached by Affiliate without the express written permission of the Company. The permission must be in written form and signed by an officer of the Company and have not violated this Agreement. Furthermore, Affiliate agrees to operate in direct contact with the Company and shall not negotiate any Agreements with current vendors, service providers, their affiliates, or any of the Company’s contacts independently without the written consent of the Company

11.2. Affiliate expressly agrees that during the Term of this Agreement and for a period of one (1) year after the termination of this Agreement, Affiliate will not, or on behalf of any other person, business, partnership or entity, engage directly or indirectly, in the business of operating, owning, contracting with, working, carrying on a business or engage in any practice to develop, market, manufacture, design or market the Company’s services, or otherwise compete either directly or indirectly with the Company. Further, Affiliate agrees that it will not, for Affiliate, or on behalf of or in conjunction with any other person, partnership, business or entity, directly or indirectly, solicit, divert or attempt to solicit or divert any of the Company’s employees, contractors, representatives, affiliates, customers, or clients or the business or patronage of any such customers or clients or business contacts as previously mentioned. Affiliate agrees to keep in the utmost confidence and not to divulge to any party or entity other than those associated with the Company all trade secrets, and Confidential Information as herein stated. Affiliate agrees that such restriction of competition and confidentiality of information is absolutely necessary for the protection of the Company’s business

11.3. Affiliate hereby agrees that the promises contained herein are reasonable, and necessary for the protection of the Company’s business. Affiliate recognizes that the Company will suffer irreparable damage if, during Affiliate’s relationship with the Company, or at any time subsequent to the termination of such relationship, Affiliate should divulge any Confidential Information, wrongfully compete against the Company, or perform any act that is in violation of the terms of this Agreement. Affiliate further recognizes that it will be difficult, if not impossible, to compute the damage to the Company as a result of any such disclosure or unauthorized competition, and that, therefore, the Company is without adequate legal remedy. In such an event, Affiliate expressly agrees that the Company shall be entitled to apply to any court of competent jurisdiction to enjoin any breach, threatened or actual, compensatory, and other damages. Affiliate further acknowledges that Affiliate has full understanding of all of the terms of this paragraph and hereby agrees that should Affiliate decide to compete with the Company, Affiliate shall pay for all attorney’s fees as may be proven in addition to any other remedies, equitable or legal as may be available in a court of law


12. License. The Company hereby grants Affiliate a license to use its name, Technique Inside Sales, LLC, or “claim my credit/ setccredit.org”, or a derivative thereof, during the Term of this Agreement. Affiliate agrees to use the name, Technique inside sales, LLC, or “claim my credit/ setccredit.org” a derivative thereof, solely in connection with marketing and promoting of the Company’s business and Services as provided in the terms of this Agreement

13. Severability of Restrictive Clauses. Each clause contained in this Agreement is separate and distinct from every other clause set forth in each paragraph/section, and in the event that any of the clauses herein are declared invalid for any reason, the remaining clauses shall be considered separate, independent, and divisible, and shall remain fully enforceable

14. Binding Arbitration. Any unresolved dispute under this Agreement may, at the election of the Company, be referred to and settled by means of binding arbitration in accordance with both the substantive and procedural laws of Chapter 11 of Title 78B of the Utah Code (“Utah Uniform Arbitration Act”) and the National Rules for the Resolution of Employment Disputes of the American Arbitration Association (the “Arbitration Rules”). In the event of conflict or inconsistency between or among the Utah Uniform Arbitration Act, the Arbitration Rules, and/or the terms and conditions of this Agreement, such conflict or inconsistency shall be resolved by giving precedence in the following order: (i) this Agreement; (ii) Utah Uniform Arbitration Act (iii) the Arbitration Rules. Judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The Company may commence the arbitration process at any stage of the legal or dispute resolution process by giving the other party written notice of the demand for arbitration. The dispute will be submitted to an independent arbitrator as is agreeable to the Company and Affiliate within 60 days after the Affiliate’s receipt of the notice of demand for arbitration. The Parties hereto agree to equally share any Arbitration filing fee to refer the dispute to the American Arbitration Association or other independent Arbitrator. The Arbitrator shall have the power and discretion to award any portion of the cost of this fee to the prevailing party

The arbitration shall be conducted in Salt Lake County or Utah County, in the State of Utah. Nothing in this Section shall prohibit any Party from seeking emergency equitable relief pending Arbitration. In addition to the right under the Rules to petition the court for provisional relief, Affiliate agrees that any Party may also petition the court for injunctive relief where either Party alleges or claims a violation of any section of this Agreement or any other agreement regarding trade secrets, confidential information, non-solicitation, or noncompetition. In the eventeither the Company or Affiliate seeks injunctive relief, the prevailing party will beentitled to recover reasonable costs and attorneys’ fees. Furthermore, the prevailingparty in any dispute between the Parties concerning the terms and provisions ofthis Agreement shall be entitled to collected from the other party all fees, costs,and expenses incurred in such dispute, including without limitation, suchreasonable attorneys’ fees and expenses

15. Affiliate Representations and Warranties. Affiliate represents, warrants, and covenants to the Company that:
15.1. Affiliate has the legal power, right and authority to enter into this Agreement and the instruments to be executed by Affiliate pursuant to this Agreement and to consummate the transactions contemplated hereby

15.2. All requisite corporate action has been taken by Affiliate in connection with Affiliate’s execution of this Agreement and the instruments to be executed by Affiliate pursuant to this Agreement and the consummation of the transactions contemplated hereby

15.3. The individuals executing this Agreement and the instruments to be executed by Affiliate pursuant to this Agreement on behalf of Affiliate, have the legal power, right and actual authority to bind Affiliate to the terms and conditions of this Agreement and such instruments

15.4. Neither the execution of this Agreement nor the consummation of the transactions contemplated hereby shall result in a breach of or constitute a default under any agreement, document, instrument, or other obligation to which Affiliate is a party or by which Affiliate may be bound, or under any law, statute, ordinance, rule, governmental regulation or any writ, injunction, order or decree of any court or governmental body, applicable to Affiliate

15.5. There is no claim, action, litigation, arbitration, material dispute or other proceeding pending against Affiliate which relates to the services contemplated hereby except as disclosed in writing to the Company, and to Affiliate’s actual knowledge, there is currently no governmental investigation, threatened litigation or arbitration proceedings to which Affiliate is, or would be, a party which relates to or would relate to the services herein


15.6. No attachments, execution proceedings, assignments for the benefit of creditors, insolvency, bankruptcy, reorganization, or other proceedings are pending or threatened against Affiliate nor are any such proceedings contemplated by Affiliate

15.7. Affiliate is a duly constituted and validly existing entity under the laws of the state(s) where it operates, duly qualified to do business in the state(s) in which the services are to be performed pursuant to this Agreement and has the full power to carry out the services and transactions contemplated by this Agreement

15.8. Affiliate certifies and agrees that Affiliate is fully familiar with all terms, conditions, and obligations of this Agreement

16. Miscellaneous Provisions

16.1. Affiliate agrees to keep all of the terms of this Agreement confidential, except that certain terms may be confidentially disclosed to officers and employees of the Company as required in the ordinary course of business

16.2. This Agreement shall be binding upon, and inure to the benefit of, the successors, executors, heirs, representatives, administrators and permitted assigns of the Parties hereto. Affiliate shall have no right to assign this Agreement by operation of law or otherwise. This Agreement and any Attachments hereto may not be amended or modified otherwise than by a written agreement executed by the Parties hereto

16.3. Any term or provision of this Agreement may be amended, and the observance of any term of this Agreement may be waived, only by a writing signed by the Party to be bound. The failure of the Company at any time to enforce performance by Affiliate of any provisions of this Agreement shall in no way affect the Company’s rights thereafter to enforce the same, nor shall the waiver by the Company of any breach of any provision hereof be held to be a waiver of any other breach of the same or any other provision

16.4. Affiliate agrees to indemnify and hold harmless the Company and its directors, officers and employees from and against all taxes, losses, damages, liabilities, costs and expenses, including attorneys’ fees and other legal expenses, arising directly or indirectly from or in connection with (i) any negligent, reckless or intentionally wrongful act of Affiliate or its

Personnel; (ii) a determination by a court or agency that Affiliate is not an independent contractor; (iii) any breach by Affiliate or its Personnel of any of the covenants, terms or conditions contained in this Agreement or in any other policies of the Company as may be in effect from time to time; or (iv) any failure of Affiliate or its Personnel to perform the Services in accordance with all applicable laws, rules and regulations. Any unpaid Compensation or monies owed to Affiliate may be used to cover claims for damages. The prevailing party in any action, suit, or proceeding brought to enforce the terms of this Agreement shall be entitled to its costs and expenses, including attorney fees, from the other party

16.5. The language in all parts of this Agreement shall in all cases be construed simply, as a whole and in accordance with its fair meaning and not strictly for or against any Party. The captions of this Agreement are not part of the provisions hereof and shall have no force or effect. The Parties acknowledge and agree that this Agreement has been negotiated by the Parties, that each Party has been given the opportunity to independently review this Agreement with legal counsel, and that each Party has the requisite experience and sophistication to understand, interpret and agree to the particular language of the provisions hereof. Accordingly, in the event of an ambiguity in or dispute regarding the interpretation of this Agreement, this Agreement shall not be interpreted or construed against the Party preparing it

16.6. Entire Agreement. This Agreement contains the entire agreement and understanding related to this specific position, between Affiliate and the Company for the Services specified herein and supersedes any prior or cotemporaneous agreements, understandings, communications, offers, representations, warranties, or commitments by or on behalf of theCompany (oral or written)

`
