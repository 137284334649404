import { Checkbox, FormControl, FormControlLabel, FormHelperText, Typography } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { FormInputProps } from '../FormTypes'

export const FormInputCheckbox: React.FC<FormInputProps> = ({ name, control, label, rules, helpText }) => {
  return (
    <Controller
      rules={rules}
      name={name}
      control={control}
      render={({ field: props, fieldState: { error } }) => {
        return (
          <FormControl error={!!error}>
            <FormControlLabel
              control={
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={(e) => props.onChange(e.target.checked)}
                  size="small"
                />
              }
              label={label}
              required={!!rules?.required}
            />
            {helpText || error?.message ? (
              <Typography paddingLeft={2} variant={'caption'} color={error?.message ? 'red' : undefined}>
                {error?.message ?? helpText}
              </Typography>
            ) : null}
          </FormControl>
        )
      }}
    />
  )
}
