'use client'
import ThemeComponent from '@core/theme/ThemeComponent'
import { hexToRGBA } from '@core/utils/hex-to-rgba'
import { Description, Logout, Menu as MenuIcon } from '@mui/icons-material'
import { AppBar, Box, IconButton, ListItemIcon, Menu, MenuItem, Paper, Typography, useTheme } from '@mui/material'
import { config } from 'dotenv'
import { useAtomValue } from 'jotai'
import { useRouter } from 'next/navigation'
import { FunctionComponent, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { userIdAtom, userTokenAtom, userTypeAtom } from './atoms'
import ResumeButton from './components/ResumeButton'
import useGTagOnRender, { trackShare } from './hooks/useGTagOnRender'
import useLogout from './hooks/useLogout'
import { ReferralSection } from './steps/TaxDashboard/ReferralSection'

config()

const queryClient = new QueryClient()

export interface IpageProps {}

const Page: FunctionComponent<IpageProps> = function Page(props) {
  const userToken = useAtomValue(userTokenAtom)

  const InnerComponent = () => (userToken ? <PageInner /> : <StartOrResume />)
  // const InnerComponent = () => <PageInner />

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex-1 flex flex-col" style={{ minHeight: '100vh', alignSelf: 'stretch' }}>
        <ThemeComponent
          settings={{
            themeColor: 'primary',
            mode: 'light',
            skin: 'default',
            footer: 'static',
            layout: 'vertical',
            lastLayout: 'vertical',
            direction: 'ltr',
            navHidden: false,
            appBarBlur: true,
            navCollapsed: false,
            contentWidth: 'boxed',
            toastPosition: 'top-right',
            verticalNavToggleType: 'accordion',
            appBar: 'fixed',
          }}>
          <InnerComponent />
        </ThemeComponent>
      </div>
    </QueryClientProvider>
  )
}

const StartOrResume = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        // backgroundImage: `url('/large_wave_bg.jpg')`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center',
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
      }}>
      <Paper
        elevation={4}
        style={{
          padding: 8,
          minWidth: 400,
          minHeight: 300,
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'center',
          borderRadius: 16,
        }}>
        <div className="flex flex1 flex-col justify-center items-center gap-4">
          <img
            src="/setcredit_logo.avif"
            style={{
              height: 44,
              width: 148,
              backgroundColor: 'black',
            }}
          />
          <Typography paddingTop={12}>You will need to login to view the dashboard.</Typography>
          <ResumeButton buttonText={'Login to View'} showStartNew />
        </div>
      </Paper>
    </Box>
  )
}

const PageInner: FunctionComponent<IpageProps> = function PageInner(props) {
  useGTagOnRender('referral-dashboard')
  const userId = useAtomValue(userIdAtom)
  // https://setccredit.org/?ref=OZ6Aqcfk

  // https://setccredit.org/?ref=n85T18uT

  return (
    <>
      <AppBar
        color="default"
        className="layout-navbar-and-nav-container"
        position="static"
        elevation={0}
        sx={{
          alignItems: 'center',
          color: 'text.primary',
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 13,
          paddingRight: 4,
          paddingLeft: 4,
          paddingTop: 2,
          paddingBottom: 2,
          transition: 'border-bottom 0.2s ease-in-out',
          backgroundColor: (theme) => hexToRGBA(theme.palette.background.paper, 1),
        }}>
        <img
          src="/setcredit_logo.avif"
          style={{
            height: 44,
            width: 148,
            backgroundColor: 'black',
          }}
        />
        <div className="flex-1 flex flex-row justify-center">Referral Dashboard</div>
        <UserMenu />
      </AppBar>
      <div className="flex-1 flex flex-col  items-stretch pb-8" style={{ paddingTop: 72 }}>
        <ReferralSection trackShare={trackShare} />
      </div>
    </>
  )
}

export default Page

function UserMenu() {
  const userType = useAtomValue(userTypeAtom)
  const router = useRouter()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const logout = useLogout()

  const hasApplication = ['applicant', 'both'].includes(userType)

  return (
    <>
      <IconButton
        onClick={handleClick}
        // className={'primary-button'}
        size="large"
        // sx={{ ml: 2, alignSelf: 'center' }}
        aria-controls={menuOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}>
        <MenuIcon />
      </IconButton>

      <Menu
        disableScrollLock
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {hasApplication ? (
          <MenuItem
            onClick={() => {
              router.push('/')
              handleClose()
            }}>
            <ListItemIcon>
              <Description fontSize="small" />
            </ListItemIcon>
            Resume Application
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            logout()
            handleClose()
          }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

//application numbers
//started, submitted, approved, rejected

//amounts
//
